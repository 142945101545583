.footer-navigation-container {
    margin-block: 0.5rem;
}

.footer-social-media {
    margin-inline: 0.5rem;
    width: 25px;
    height: 25px;
cursor: pointer;
}

.footer-social-media:first-child {
    margin-left: 0;
}

.footer-social-media:hover {

    fill: #f79723;
}