.grid-container {
    display: flex;
    flex: 1;
    padding: 10px;
    justify-content: center;

}

.grid-item-product:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);

    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

.grid-item-product {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    text-align: center;
    min-width: 160px;
    margin-inline: 0.5rem;
    margin-block: 1rem;
    max-width: 160px;
    box-shadow: 5px 2px 10px 1px rgb(0 0 0 / 14%);
    position: relative
}

.offerTag {
    font-size: 12;
    background-color: 'red';
    color: '#fff';
    padding-inline: 5;
    text-align: 'center';
    font-family: 'BeVietnamPro-Regular';
    border-top-right-radius: 2;
    border-bottom-right-radius: 2;
}

.stockWrapper {
    padding-inline: 10;
    justify-content: 'flex-start';
    margin-top: 10;
    flex: 5;
    min-height: 100;
}

.productDetailsWrapper {
    padding: 10;
    justify-content: 'center';
    align-items: 'center';
    flex: 1;
    margin-bottom: 10;
}


.quantityWrapperFeaturedProducts {
    display: flex;
    flex-direction: row;
    border: 0.4px solid #00000032;
    justify-content: space-between;
    align-items: center;
}

.cartWrapper {
    display: flexbox;
    border-radius: 1px;
    padding: 0.21rem;
    align-items: center;
    flex: 1;
    border: 0.4px solid #00000032;
}

.image-featured {
    width: 100%;
    height: 100px
}

@media only screen and (min-width:992px) {
    .image-featured{
        height: 150px;
        width: 100%;
    }
    .grid-item-product {
        max-width: 250px;
        min-width: 200px;
        min-height: 200px;
        padding: 1rem;
        border: 0.1px solid #00000032;

    }
}