.cart-large-quantitywrapper {
    display: flex;
    flex-direction: row;
    border: 0.4px solid #00000032;
    justify-content: space-between;
    align-items: center;
}

.quantityWrapper {
    display: flex;
    flex-direction: row;
    border: 0.4px solid #00000032;
    justify-content: space-between;
    align-items: center;
    width: 6rem;
}

.removeButtonWraper {
    color: 'red';
    width: 35;
    height: 35;
    justify-content: 'center';
    align-items: 'center';
    padding: 5;
    border-radius: 10;
    align-self: 'baseline';
}

.offerTag {
    font-size: 12;
    background-color: 'red';
    color: '#fff';
    padding-inline: 1rem;
}

.cart-page-total {
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4rem;
    border-top-width: 0.5px;
    border-top-color: #707070;
    padding-top: 1rem;
    display: flex;
    border-top-style: solid;
    margin-inline: 1rem;
}

.cart-page-large-order-button {
    display: none;
}

@media only screen and (min-width:992px) {
    .cart-page-container {
        padding-inline: 10rem;
    }

    th,

    th {
        padding-block: 2rem;
    }

    td:first-child {
        text-align: left;
    }

    td:nth-child(2) {
        text-align: left;
    }

    th:nth-child(2) {
        text-align: left;

    }

    table {
        text-align: center;
        min-width: 100%;

    }

    .cart-page-large-order-button {
        display: flex;
        background-color: #1d4e4b;
        padding-inline: 1rem;
        padding-block: 0.5rem;
        border-radius: 32px;
        color: #ffffff;
        cursor: pointer;
    }

    .cart-page-large-order-button:hover {
        background-color: #f79723
    }

    .cart-page-total {
        margin-inline: 0
    }
}