.ShareCatalogueField {
    margin-top: 1rem;

}

.order-page-large-cancel-button {
    display: flex;
    background-color: #ffffff;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-radius: 32px;
    color: #000000;
    border:0.4px solid #707070;
    margin-right:1rem;
    cursor: pointer;
}
