.BottomNav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    height: 4rem;
    background-color: white;
    display: flex;
    box-shadow: 5px -2px 10px 5px rgb(0 0 0 / 14%);
    align-items: center;
    justify-content: center;
    padding-inline: 1rem;
}

.BottomBtn {
    display: flex;
    flex: 2;
    align-items: center;
    padding-block: 0.8rem;
    border-radius: 12px;

}

.BottomIconBtn {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    
}

@media only screen and (min-width:992px) {
    .BottomNav{
        display: none;
    }
}