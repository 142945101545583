.grid-container-category {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    align-items: center;
    justify-items: center;
    padding: 10px;
    gap: 1rem;
    grid-auto-rows: max-content;
    /* grid: auto-flow/repeat(auto-fill, minmax(250px, 1fr)); */
}

/* .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 100px);
    grid-auto-rows: 150px;
    justify-content: center;
  } */

.grid-item {
    display: flex;
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    flex-direction: column;
    text-align: center;
    min-width: 100%;
    margin-inline: 1rem;
    margin-block: 1rem;
    max-width: 150px;
    box-shadow: 5px 2px 10px 1px rgb(0 0 0 / 14%);
    align-items: center;
    min-height: 100%;
}

.grid-item:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);

    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
}

.image-category {
    width: 100%;
    height: 120px;
    object-fit: cover;
    aspect-ratio: 16/5;
}

.name-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

}

.category-name {
    display: flex;
    flex: 1;
    font-family: 'Poppins-Regular';
    margin-bottom: 0;
    font-size: large;
    margin-inline: 0.3rem;
    text-align: center;
    overflow-wrap: anywhere;
}

@media only screen and (min-width:992px) {
    .grid-item {
        max-width: 250px;
        min-width: 100%;
        /* min-height: 200px; */
        border: 0.1px solid #00000032;
        padding: 1rem;

    }

    .image-category {
        width: 100%;
        height: 150px;
        object-fit: cover;
        aspect-ratio: 16/9;
    }

    .category-name {
        margin-inline: 0rem;
    }

    .grid-container-category {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        align-content: center;
        justify-items: center;
        padding: 10px;
        gap: 1rem;

        /* grid: auto-flow/repeat(auto-fill, minmax(250px, 1fr)); */
    }

}