.containerHeaderCommon {
    margin-block: 2rem;
    display: flex;
    align-items: center;
    justify-content: 'center';
    flex: 1;
    flex-shrink: 1;
    margin-inline: 1rem;
}

.header-common-heading {
    display: none;
}

@media only screen and (min-width:992px) {
    .containerHeaderCommon {
        display: none;
    }

    .header-common-heading {
        display: flex;
        font-family: 'Poppins-Regular';
        font-size: 20px;
    }
}