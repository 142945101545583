.containerHeaderProduct {
    display: flex;
    align-items: 'center';
    padding-block: 2rem;
    justify-content: center;
}

.drawer-icon-product {
    margin-left: 1rem;
    justify-content: 'center';
    display: flex;
    align-items: center;
}

@media only screen and (min-width:992px) {
    .drawer-icon-product {
        display: none;
    }
}